import { Box } from '@mui/material';
import useAuth from 'src/hooks/useAuth';

function AppInit() {
  const auth = useAuth();
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {
        auth.isAuthenticated ? <img src="/static/logo.svg" width={800} height={600} /> : <h3>Survias Dashboard</h3>
      }
    </Box>
  );
}

export default AppInit;
