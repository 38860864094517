import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Card,
    Link,
    Tooltip,
    Typography,
    Container,
    Alert,
    styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
// import useAuth from 'src/hooks/useAuth';
// import Auth0Login from '../LoginAuth0';
// import FirebaseAuthLogin from '../LoginFirebaseAuth';
import JWTLogin from './LoginJWT';
// import AmplifyLogin from '../LoginAmplify';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';

const icons = {
    Auth0: '/static/images/logo/auth0.svg',
    FirebaseAuth: '/static/images/logo/firebase.svg',
    JWT: '/static/images/logo/jwt.svg',
    Amplify: '/static/images/logo/amplify.svg'
};

const CardImg = styled(Card)(
    ({ theme }) => `
    width: 90px;
    height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: ${theme.colors.alpha.white[100]};
    margin: 0 ${theme.spacing(1)};
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['all'])};

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const BottomWrapper = styled(Box)(
    ({ theme }) => `
    padding: ${theme.spacing(3)};
    display: flex;
    align-items: center;
    justify-content: center;
`
);

const MainContent = styled(Box)(
    () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
    () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function LoginContent() {
    //   const { method } = useAuth() as any;
    const { t }: { t: any } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('Login')}</title>
            </Helmet>
            <MainContent>
                <TopWrapper>
                    <Container maxWidth="sm">
                        <Logo />
                        <Card
                            sx={{
                                mt: 3,
                                px: 4,
                                pt: 5,
                                pb: 3
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        mb: 1
                                    }}
                                >
                                    {t('Sign in')}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color="text.secondary"
                                    fontWeight="normal"
                                    sx={{
                                        mb: 3
                                    }}
                                >
                                    {t('Fill in the fields below to sign into your account.')}
                                </Typography>
                            </Box>
                            <JWTLogin />
                            <Box my={4}>
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                    color="text.primary"
                                    fontWeight="bold"
                                >
                                    {t('Don’t have an account, yet?')}
                                </Typography>{' '}
                                <Link component={RouterLink} to="/user/register">
                                    <b>{t('Sign up here')}</b>
                                </Link>
                            </Box>
                        </Card>
                    </Container>
                </TopWrapper>
            </MainContent>
        </>
    );
}

export default LoginContent;