import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

const IS_TESTING = window.location.host.includes("uat");

const axiosInt = axios.create({  
  baseURL: IS_TESTING ? "https://ssr-api-uat.survias.cl" : "https://ssr-api.survias.cl",
  headers: {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Expose-Headers":"authorization",
    "Access-Control-Allow-Headers":"*"
    // "Access-Control-Allow-Headers": "Origin, Authorization, x-requested-with,Access-Control-Allow-Methods,Content-Type, X-File-Type, X-File-Name, X-File-Size, Content-Range, Content-Disposition"
  }
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      error.response
    )
);

// export const mock = new AxiosMockAdapter(axiosInt, { delayResponse: 0 });

export default axiosInt;