import { PartialRouteObject } from 'react-router';
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Authenticated from 'src/components/Authenticated';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import driverRoutes from './driver';
import operatorRoutes from './operator';
import adminRoutes from './admin';
import supervisorRoutes from './supervisor';
import inspectorRoutes from './inspector';
import accountRoutes from './account';

import SuspenseLoader from 'src/components/SuspenseLoader';
import LoginContent from '../content/Login/index';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

const Status404 = Loader(
    lazy(() => import('src/content/pages/Status/Status404'))
);

export const routerDriver: PartialRouteObject[] = [
    {
        path: '/',
        element: (
            <Authenticated>
                <AccentSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="driver" replace />
            },
            {
                path: 'driver',
                children: driverRoutes
            }
        ]
    },
    {
        path: '*',
        element: <Status404 />
    }
];

export const routerOperator: PartialRouteObject[] = [
    {
        path: '/',
        element: (
            <Authenticated>
                <AccentSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="operator" replace />
            },
            {
                path: 'operator',
                children: operatorRoutes
            },
        ]
    },
    {
        path: '*',
        element: <Status404 />
    }
];

export const routerAdmin: PartialRouteObject[] = [
    {
        path: '/',
        element: (
            <Authenticated>
                <AccentSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="admin" replace />
            },
            {
                path: 'admin',
                children: adminRoutes
            },
        ]
    },
    {
        path: '*',
        element: <Status404 />
    }
];

export const routerInspector: PartialRouteObject[] = [
    {
        path: '/',
        element: (
            <Authenticated>
                <AccentSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="inspector" replace />
            },
            {
                path: 'inspector',
                children: inspectorRoutes
            },
        ]
    },
    {
        path: '*',
        element: <Status404 />
    }
];

export const routerOperatorFiscal: PartialRouteObject[] = [
    {
        path: '/',
        element: (
            <Authenticated>
                <AccentSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '/',
                element: <Navigate to="supervisor" replace />
            },
            {
                path: 'supervisor',
                children: supervisorRoutes
            },
        ]
    },
    {
        path: '*',
        element: <Status404 />
    }
];

export const routerDefault: PartialRouteObject[] = [
    {
        path: '/',
        element: <Authenticated>
            <AccentSidebarLayout />
        </Authenticated>,
    },
    {
        path: 'user',
        children: accountRoutes
    },
    {
        path: '*',
        element: <Status404 />
    }
];
