import { Suspense, lazy } from 'react';

import SuspenseLoader from '../components/SuspenseLoader';
import { Navigate } from 'react-router-dom';

const Loader = (Component: any) => (props: any) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

// Account

const LoginBasic = Loader(
    lazy(() => import('../content/Login'))
);


const RecoverPassword = Loader(
    lazy(() => import('src/content/Login/RecoveryPassword'))
);

const RegisterUser = Loader(
    lazy(() => import('src/content/users/register'))
);

const accountRoutes = [
    {
        path: '/',
        element: <Navigate to="login" replace />
    },
    {
        path: 'login',
        element: <LoginBasic />
    },
    {
        path: 'recover-password',
        element: <RecoverPassword />
    },
    {
        path: 'register',
        element: <RegisterUser />
    }
];

export default accountRoutes;
